/* eslint-disable no-param-reassign */
import { WorkflowTriggerConditionType } from '@kindo/universal';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { WorkflowTriggerCondition } from '~/types';

const NEW_EMPTY_TRIGGER_CONDITION: WorkflowTriggerCondition = {
  id: '',
  field: '',
  type: WorkflowTriggerConditionType.EQUALS,
  value: '',
  workflowTriggerId: ''
};

interface ConditionState {
  editingCondition: WorkflowTriggerCondition | null;
}

const initialState: ConditionState = {
  editingCondition: null
};

export const workflowBuilderTriggerConditionSlice = createSlice({
  name: 'workflowBuilderCondition',
  initialState,
  reducers: {
    setEditingCondition: (
      state,
      action: PayloadAction<WorkflowTriggerCondition>
    ) => {
      state.editingCondition = action.payload;
    },

    createNewCondition: (state, action: PayloadAction<string>) => {
      state.editingCondition = {
        ...NEW_EMPTY_TRIGGER_CONDITION,
        workflowTriggerId: action.payload
      };
    },

    clearEditingCondition: (state) => {
      state.editingCondition = null;
    },

    updateEditingCondition: (
      state,
      action: PayloadAction<Partial<WorkflowTriggerCondition>>
    ) => {
      if (state.editingCondition) {
        state.editingCondition = {
          ...state.editingCondition,
          ...action.payload
        };
      }
    }
  }
});

export const conditionActions = workflowBuilderTriggerConditionSlice.actions;
