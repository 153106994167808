import _styled from "styled-components";
const ToggleContainer = _styled.label.withConfig({
  displayName: "Toggle__ToggleContainer",
  componentId: "sc-1p5sy55-0"
})({
  "position": "relative",
  "display": "inline-flex",
  "height": "1.5rem",
  "width": "2.75rem",
  "cursor": "pointer",
  "alignItems": "center"
});
const StyledInput = _styled.input.withConfig({
  displayName: "Toggle__StyledInput",
  componentId: "sc-1p5sy55-1"
})({
  "position": "absolute",
  "width": "1px",
  "height": "1px",
  "padding": "0",
  "margin": "-1px",
  "overflow": "hidden",
  "clip": "rect(0, 0, 0, 0)",
  "whiteSpace": "nowrap",
  "borderWidth": "0"
});
const StyledToggleDisplay = _styled.div.withConfig({
  displayName: "Toggle__StyledToggleDisplay",
  componentId: "sc-1p5sy55-2"
})(({
  $checked,
  $disabled
}) => [{
  "height": "1.5rem",
  "width": "2.75rem",
  "borderRadius": "9999px",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(209 213 219 / var(--tw-bg-opacity))",
  "&::after": {
    "content": "var(--tw-content)",
    "position": "absolute",
    "left": "2px",
    "top": "0.125rem",
    "height": "1.25rem",
    "width": "1.25rem",
    "borderRadius": "9999px",
    "borderWidth": "1px",
    "--tw-border-opacity": "1",
    "borderColor": "rgb(209 213 219 / var(--tw-border-opacity))",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(255 255 255 / var(--tw-bg-opacity))",
    "transitionProperty": "all",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "150ms",
    "--tw-content": "''"
  }
}, $checked && {
  ".peer:checked ~ &": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(30 211 176 / var(--tw-bg-opacity))"
  },
  ".peer:checked ~ &::after": {
    "content": "var(--tw-content)",
    "--tw-translate-x": "100%",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
    "--tw-border-opacity": "1",
    "borderColor": "rgb(255 255 255 / var(--tw-border-opacity))"
  }
}, $disabled && {
  "cursor": "not-allowed",
  "opacity": "0.4"
}]);
interface ToggleProps {
  checked: boolean;
  onToggle: (isChecked: boolean) => void;
  disabled?: boolean;
}
const Toggle: React.FC<ToggleProps> = ({
  checked,
  disabled = false,
  onToggle
}) => <ToggleContainer>
    <StyledInput checked={checked} className="peer" disabled={disabled} onChange={e => !disabled && onToggle(e.target.checked)} type="checkbox" />
    <StyledToggleDisplay $checked={checked} $disabled={disabled} className="peer" />
  </ToggleContainer>;
export default Toggle;