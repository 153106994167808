/** Return a random item from an array */
export const randomElem = <T>(arr: Array<T>) =>
  arr[Math.floor(Math.random() * arr.length)];

/** Uppercase the first letter of a string */
export const upperCaseFirstLetter = (s: string) =>
  s && (s[0]?.toUpperCase() ?? '') + s.slice(1).toLowerCase();

/** Conditionally insert an item into an array */
export const insertIf = <T>(condition: boolean, ...elements: T[]): T[] =>
  condition ? elements : [];

/**
 * Returns a copy of the given array with items inserted into the provided index.
 * @param array - initial array
 * @param index - index to insert items into
 * @param rest - items to be inserted into the array
 */
export const arrayInsert = <T>(
  array: T[],
  index: number,
  ...rest: T[]
): T[] => {
  const arr = [...array];
  arr.splice(index, 0, ...rest);
  return arr;
};

export function isValidRegex(pattern: string) {
  try {
    RegExp(pattern);
    return true; // The pattern is a valid regex
  } catch (e) {
    return false; // The pattern is not a valid regex
  }
}
