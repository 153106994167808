import { insertIf } from '@kindo/universal';

import { Icon } from '~/components/core';
import { DropdownItemProps } from '~/components/Dropdown/DropdownItem';

interface UseFileDropdownItemsReturn {
  getFileDropdownItems: (args: {
    contentId: string;
    onContentDelete: () => void;
    showDelete: boolean;
  }) => DropdownItemProps[];
}

const useFileDropdownItems = (): UseFileDropdownItemsReturn => {
  const getFileDropdownItems = ({
    onContentDelete,
    showDelete
  }: {
    contentId: string;
    onContentDelete: () => void;
    showDelete: boolean;
  }): DropdownItemProps[] => [
    ...insertIf(showDelete, {
      title: 'Delete file',
      icon: Icon.TRASH,
      onClick: () => onContentDelete(),
      destructive: true
    })
  ];

  return { getFileDropdownItems };
};

export default useFileDropdownItems;
