import _styled from "styled-components";
import clsx from 'clsx';
import React from 'react';
import { SearchInput } from '../../SearchInput';
import { Select } from '~/components/core';

// Options that can be selected for a single filter
export type FilterOption = {
  label: string;
  value: string;
};
interface Filter<FilterKey extends string> {
  defaultLabel: string;
  filterKey: FilterKey; // e.g. FilesFilter.FILE_TYPE
  options: FilterOption[];
  placeholder: string;
}

// FiltersValues are the values of ALL the filters. E.g. { FilesFilter.FILE_TYPE: 'csv' }
export type FiltersValues<K extends string> = Record<K, string | undefined>;
export interface FiltersProps<FilterKey extends string> {
  filters: Filter<FilterKey>[];
  filtersValues: FiltersValues<FilterKey>;
  searchValue: string;
  setFilters: (newFilterValues: FiltersValues<FilterKey>) => void;
  collapsed?: string | boolean;
  searchPlaceholder?: string;
  setSearchValue?: (search: string) => void;
}

/**
 * K - the type of
 */
const Filters = <FilterKey extends string,>({
  filters,
  searchValue,
  filtersValues,
  setFilters,
  setSearchValue,
  searchPlaceholder,
  collapsed = false
}: FiltersProps<FilterKey>) => {
  const [innerValue, setInnerValue] = React.useState(searchValue);
  const handleSetPartialFilter = (filterKey: FilterKey, newValue: string | number | undefined) => setFilters({
    ...filtersValues,
    [filterKey]: newValue
  });
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    setSearchValue?.(searchText.trim());
    setInnerValue(searchText);
  };
  return <_StyledDiv>
      {setSearchValue && <SearchInput onChange={handleSearchChange} placeholder={searchPlaceholder || 'Search'} value={innerValue} />}
      <div className={clsx('flex grow flex-row space-x-2', {
      'basis-full': collapsed
    })}>
        {filters.map(({
        defaultLabel,
        options,
        placeholder,
        filterKey
      }) => {
        const defaultOption: FilterOption | undefined = defaultLabel ? {
          label: defaultLabel,
          value: ''
        } : undefined;
        const optionsWithDefault = defaultOption ? [defaultOption, ...options] : options;
        return <Select key={filterKey} onChange={value => handleSetPartialFilter(filterKey, value ?? undefined)} options={optionsWithDefault} placeholderLabel={placeholder} value={filtersValues[filterKey] ?? ''} />;
      })}
      </div>
    </_StyledDiv>;
};
export default Filters;
var _StyledDiv = _styled("div").withConfig({
  displayName: "Filters___StyledDiv",
  componentId: "sc-pswyl5-0"
})({
  "display": "flex",
  "flexWrap": "wrap",
  "alignItems": "center",
  "gap": "0.75rem"
});