// This is a duplicate of Role from main.prisma.
export enum Role {
  Admin = 'Admin',
  Member = 'Member'
}

// Type guard function to check if a value is a valid Role
export function isValidOrgRole(value: any): value is Role {
  return Object.values(Role).includes(value);
}
