'use client';

import { Analytics } from '@vercel/analytics/react';
import { useEffect, useState, ReactElement } from 'react';
import { IntercomProvider } from 'react-use-intercom';
import AuthWrapper from './AuthWrapper';
import FeatureFlagWrapper from './FeatureFlagWrapper';
import OnboardingModal, { OnboardingFormData } from '~/components/Onboarding';
import { TermsOfServiceModal } from '~/components/TermsOfService';
import { useDatadog, useHeap, useUserpilot } from '~/hooks';
import useUserInfo from '~/hooks/useUserInfo';
import { nextTrpc } from '~/trpc';
const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID || '';
if (!process.env.INTERCOM_APP_ID) {
  throw new Error('Missing INTERCOM_APP_ID');
}
enum AppRootModal {
  ONBOARDING = 'ONBOARDING',
  TERMS_OF_SERVICE = 'TERMS_OF_SERVICE',
}
const ProviderWrapper = ({
  children
}: {
  children: React.ReactElement | ReactElement[];
}) => {
  // Call setup hooks
  useHeap();
  useDatadog();
  const {
    triggerUserpilotTour
  } = useUserpilot();

  // State
  const [openModal, setOpenModal] = useState<AppRootModal | null>(null);

  // Mutations
  const {
    mutateAsync: saveOnboardingAsync
  } = nextTrpc.users.updateUserOnboarding.useMutation();

  // Queries
  const {
    user,
    refetchUser
  } = useUserInfo();
  const signedToS = user?.signedTosAt !== null;
  useEffect(() => {
    if (!user) {
      setOpenModal(null);
      return;
    }

    // NOTE: this will prevent users from returning to the questions after skipping
    const skipOnboarding = user?.onboardingQuestions !== null;
    if (!signedToS) {
      setOpenModal(AppRootModal.TERMS_OF_SERVICE);
    } else if (!skipOnboarding) {
      setOpenModal(AppRootModal.ONBOARDING);
    } else {
      setOpenModal(null);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [signedToS, user]);
  const completeOnboarding = () => {
    void refetchUser();
    setOpenModal(null);
    // Trigger Userpilot tour manually to avoid collision with the onboarding modal
    triggerUserpilotTour();
  };
  const saveOnboarding = async (formData: OnboardingFormData) => {
    await saveOnboardingAsync({
      name: formData.name || user?.name || '',
      onboardingQuestions: Object.entries(formData).filter(([key]) => key !== 'name' && key !== 'skip').map(([key, value]) => ({
        question: key,
        answer: Array.isArray(value) ? value : [value]
      }))
    });
    completeOnboarding();
  };
  const skipOnboarding = async () => {
    await saveOnboardingAsync({
      onboardingQuestions: [{
        question: 'skipped',
        answer: ['true']
      }]
    });
    completeOnboarding();
  };
  return <IntercomProvider appId={INTERCOM_APP_ID} autoBoot initializeDelay={5000}>
      <AuthWrapper>
        <FeatureFlagWrapper>
          {children}
          {user &&
        // TODO: Only render if open?
        <OnboardingModal onClose={skipOnboarding} onSubmit={saveOnboarding} open={openModal === AppRootModal.ONBOARDING} user={{
          name: user.name,
          onboardingQuestions: user.onboardingQuestions
        }} />}
          {openModal === AppRootModal.TERMS_OF_SERVICE && <TermsOfServiceModal onComplete={() => {
          void refetchUser();
          setOpenModal(null);
        }} />}
        </FeatureFlagWrapper>
      </AuthWrapper>
      <Analytics />
    </IntercomProvider>;
};
export default ProviderWrapper;