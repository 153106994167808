export enum FeatureFlags {
  CODING_COPILOT = 'CODING_COPILOT',
  DOWNLOAD_CODING_COPILOT = 'DOWNLOAD_CODING_COPILOT',
  FEATURE_FLAGGED_LLMS = 'FEATURE_FLAGGED_LLMS',
  KNOWLEDGE_STORE = 'KNOWLEDGE_STORE',
  MARKDOWN_SYSTEM_PROMPT = 'MARKDOWN_SYSTEM_PROMPT',
  SLACK_INTEGRATION = 'SLACK_INTEGRATION',
  TICKETING_INTEGRATION_AGENTS = 'TICKETING_INTEGRATION_AGENTS',
  UNLIMITED_CREDIT_USAGE = 'UNLIMITED_CREDIT_USAGE'
}
