import { ToastType, useAppDispatch, useToast } from '~/hooks';
import { workflowBuilderActions } from '~/redux/reducers/workflowBuilderSlice';
import { nextTrpc } from '~/trpc';

const useDeleteWorkflowStep = (stepId: string, refetchWorkflow: () => void) => {
  const { enqueueToast } = useToast();
  const dispatch = useAppDispatch();

  const clearActiveStep = () =>
    dispatch(workflowBuilderActions.clearActiveStep());

  const deleteStepMutation = nextTrpc.workflows.deleteStep.useMutation({
    onSuccess: () => {
      refetchWorkflow();
      clearActiveStep();
      enqueueToast({
        message: 'Step deleted successfully.',
        type: ToastType.SUCCESS
      });
    },
    onError: (error) => {
      console.error('Failed to delete step: ', error);
      enqueueToast({
        message: 'Failed to delete step.',
        type: ToastType.ERROR
      });
    }
  });

  const deleteWorkflowStep = async () => {
    await deleteStepMutation.mutateAsync({
      workflowStepId: stepId
    });
  };

  return {
    deleteWorkflowStep,
    isDeleting: deleteStepMutation.isLoading
  };
};

export default useDeleteWorkflowStep;
