/* eslint-disable no-param-reassign */
import { WorkflowInputType, WorkflowTriggerOperator } from '@kindo/universal';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { WorkflowTrigger, WorkflowTriggerCondition } from '~/types';

const NEW_EMPTY_TRIGGER: WorkflowTrigger = {
  id: '',
  integrationId: '',
  integrationInputType: WorkflowInputType.TICKETING_INTEGRATION_TICKET,
  operator: WorkflowTriggerOperator.AND,
  conditions: [],
  workflowId: '',
  enabled: true
};

interface TriggerState {
  showTriggerEditor: boolean;
  trigger: WorkflowTrigger | null;
}

const initialState: TriggerState = {
  trigger: null,
  showTriggerEditor: false
};

export const workflowBuilderTriggerSlice = createSlice({
  name: 'workflowBuilderTrigger',
  initialState,
  reducers: {
    createNewTrigger: (state) => {
      state.trigger = NEW_EMPTY_TRIGGER;
      state.showTriggerEditor = true;
    },

    setShowTriggerEditor: (state, action: PayloadAction<boolean>) => {
      state.showTriggerEditor = action.payload;
      if (!action.payload && state.trigger && !state.trigger.id) {
        state.trigger = null;
      }
    },

    cancelTriggerEdit: (state) => {
      state.showTriggerEditor = false;

      if (state.trigger && !state.trigger.id) {
        state.trigger = null;
      }
    },

    updateTrigger: (state, action: PayloadAction<Partial<WorkflowTrigger>>) => {
      if (state.trigger) {
        state.trigger = { ...state.trigger, ...action.payload };
      }
    },

    clearTrigger: (state) => {
      state.trigger = null;
      state.showTriggerEditor = false;
    },

    saveTrigger: (state, action: PayloadAction<WorkflowTrigger>) => {
      state.trigger = action.payload;
      state.showTriggerEditor = false;
    },

    createNewTriggerCondition: (
      state,
      action: PayloadAction<WorkflowTriggerCondition>
    ) => {
      if (!state.trigger) return;

      const newCondition: WorkflowTriggerCondition = {
        ...action.payload,
        id: `condition-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`, // TODO: created upon db save
        workflowTriggerId: state.trigger.id
      };

      state.trigger.conditions.push(newCondition);
    },

    removeTriggerCondition: (state, action: PayloadAction<string>) => {
      if (!state.trigger) return;

      state.trigger.conditions = state.trigger.conditions.filter(
        (condition) => condition.id !== action.payload
      );
    },

    updateTriggerCondition: (
      state,
      action: PayloadAction<{ updatedCondition: WorkflowTriggerCondition }>
    ) => {
      if (!state.trigger) return;

      const { updatedCondition } = action.payload;
      const { conditions: existingConditions } = state.trigger;

      const existingConditionIndex = existingConditions.findIndex(
        (condition) => condition.id === updatedCondition.id
      );

      if (existingConditionIndex === -1) {
        console.error('No existing trigger condition found to update');
        return;
      }

      state.trigger.conditions[existingConditionIndex] = updatedCondition;
    },

    toggleTriggerEnabled: (state) => {
      if (state.trigger) {
        state.trigger.enabled = !state.trigger.enabled;
      }
    }
  }
});

export const triggerActions = workflowBuilderTriggerSlice.actions;
