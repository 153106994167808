import { v4 as uuid } from 'uuid';

import { Integration } from '../../constants';

import {
  ContentWorkflowInputPayload,
  TextWorkflowInputPayload,
  TicketIntegrationInputPayload
} from './workflowInputPayload.types';

export const MOCK_TEXT_WORKFLOW_INPUT_PAYLOAD = {
  text: 'mock-text'
} as const satisfies TextWorkflowInputPayload;
export const MOCK_CONTENT_WORKFLOW_INPUT_PAYLOAD = {
  contentId: uuid()
} as const satisfies ContentWorkflowInputPayload;
export const MOCK_TICKETING_INTEGRATION_INPUT_PAYLOAD = {
  integrationData: {
    assignees: [
      {
        email: 'assignee@example.com',
        id: 'assignee-id',
        name: 'Assignee Name'
      }
    ],
    creator: {
      email: 'creator@example.com',
      id: 'creator-id',
      name: 'Creator Name'
    },
    description: 'Mock ticket description',
    integrationName: Integration.SERVICENOW,
    title: 'Mock Ticket Title',
    url: 'https://example.com/mock-ticket'
  }
} as const satisfies TicketIntegrationInputPayload;
