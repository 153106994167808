import {
  IntegrationWorkflowInputType,
  WorkflowInputType
} from './workflow.consts';

/** Field Condition Enums */
export enum TicketingIntegrationTicketTriggerConditionField {
  ASSIGNEE_EMAIL = 'ASSIGNEE_EMAIL',
  CREATOR_EMAIL = 'CREATOR_EMAIL',
  DESCRIPTION = 'DESCRIPTION',
  TITLE = 'TITLE'
}

/** Mapping of Integration Input Type to Trigger Condition Fields */
export const INTEGRATION_INPUT_TYPE_TO_TRIGGER_CONDITION_FIELDS = {
  [WorkflowInputType.TICKETING_INTEGRATION_TICKET]: Object.values(
    TicketingIntegrationTicketTriggerConditionField
  )
} as const satisfies Readonly<
  Record<
    IntegrationWorkflowInputType,
    TicketingIntegrationTicketTriggerConditionField[]
  >
>;
