import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';

import { chatSlice } from './reducers/chatSlice';
import { modalSlice } from './reducers/modalSlice';
import { userSlice } from './reducers/userSlice';
import { workflowBuilderSlice } from './reducers/workflowBuilderSlice';
import { workflowBuilderTriggerConditionSlice } from './reducers/workflowBuilderTriggerConditionSlice';
import { workflowBuilderTriggerSlice } from './reducers/workflowBuilderTriggerSlice';

// https://redux-toolkit.js.org/tutorials/quick-start#create-a-redux-store
export const makeStore = () =>
  configureStore({
    reducer: {
      [chatSlice.name]: chatSlice.reducer,
      [modalSlice.name]: modalSlice.reducer,
      [userSlice.name]: userSlice.reducer,
      [workflowBuilderSlice.name]: workflowBuilderSlice.reducer,
      [workflowBuilderTriggerConditionSlice.name]:
        workflowBuilderTriggerConditionSlice.reducer,
      [workflowBuilderTriggerSlice.name]: workflowBuilderTriggerSlice.reducer
    },
    devTools: process.env.NODE_ENV !== 'production'
  });

export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore['dispatch'];

// https://blog.logrocket.com/use-redux-next-js/
export const reduxWrapper = createWrapper<AppStore>(makeStore);
