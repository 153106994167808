/** Integration Data Models, (often based on Merge but decoupled) */
/** Named as <category>Integration<model> */
/** Categories come from integrations.consts.ts */

import { z } from 'zod';

import { Integration, MergeIntegration } from '../../constants';

// Based on MergeTicketingIntegrationUser
export const zTicketingIntegrationUser = z.object({
  email: z.string(),
  id: z.string(),
  name: z.string()
});

export type TicketingIntegrationUser = z.infer<
  typeof zTicketingIntegrationUser
>;

// TODO: Finish adding all fields
// TODO: Create transformer from MergeTicketingIntegrationTicket to TicketingIntegrationTicket
// TODO: Make Zod schema so that we can safe parse from WorkflowInputValue's stored in DB

// IMPORTANT: These values are saved in the DB as specified fields in WorkflowInputStep config,
// and should not be changed
export enum TicketingIntegrationTicketField {
  ASSIGNEES = 'assignees',
  CREATOR = 'creator',
  DESCRIPTION = 'description',
  INTEGRATION_NAME = 'integrationName',
  MERGE_ID = 'mergeId',
  TITLE = 'title',
  URL = 'url'
}

export const TICKETING_INTEGRATION_TICKET_FIELDS = Object.values(
  TicketingIntegrationTicketField
);

// Similar MergeTicketingIntegrationTicket, with some fields removed/modified
// TODO: Add all required fields
export const zTicketingIntegrationTicket = z.object({
  [TicketingIntegrationTicketField.ASSIGNEES]: z.array(
    zTicketingIntegrationUser
  ),
  [TicketingIntegrationTicketField.CREATOR]: zTicketingIntegrationUser,
  [TicketingIntegrationTicketField.DESCRIPTION]: z.string(),
  [TicketingIntegrationTicketField.INTEGRATION_NAME]: z.nativeEnum(Integration),
  [TicketingIntegrationTicketField.TITLE]: z.string(),
  [TicketingIntegrationTicketField.URL]: z.string().url()
});

export type TicketingIntegrationTicket = z.infer<
  typeof zTicketingIntegrationTicket
>;

export interface MergeTicketingIntegrationTicket
  extends TicketingIntegrationTicket {
  [TicketingIntegrationTicketField.INTEGRATION_NAME]: MergeIntegration;
  [TicketingIntegrationTicketField.MERGE_ID]: string;
}
