import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Userpilot } from 'userpilot';

import useUserInfo from '../useUserInfo';

const USERPILOT_TOKEN = process.env.USERPILOT_TOKEN || '';
if (!process.env.USERPILOT_TOKEN) {
  throw new Error('Missing USERPILOT_TOKEN');
}
const USERPILOT_TOUR_ID = '1692211916eXbw5233';

/**
 * Sets up User Pilot
 */
const useUserpilot = () => {
  const router = useRouter();

  const { user } = useUserInfo();

  // Start user pilot. Setup doc:
  // https://docs.userpilot.com/article/22-install-userpilot-on-single-page-application-frameworks
  const startUserpilot = () => Userpilot.initialize(USERPILOT_TOKEN);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      startUserpilot();
    }
  }, []);
  // Identify user on user pilot
  useEffect(() => {
    if (!user?.id) return;

    // Identify in User Pilot
    // https://docs.userpilot.com/article/22-install-userpilot-on-single-page-application-frameworks
    Userpilot.identify(user.id, {
      name: user.name,
      email: user.email
    });
  }, [user?.email, user?.id, user?.name]);

  // Reload user pilot on route change, see setup doc link above
  useEffect(() => {
    Userpilot.reload();
  }, [router.pathname]);

  const triggerUserpilotTour = () => Userpilot.trigger(USERPILOT_TOUR_ID);

  return { triggerUserpilotTour };
};

export default useUserpilot;
