import { z } from 'zod';

import {
  IntegrationWorkflowInputType,
  WorkflowInputType
} from '../../constants';
import { zTicketingIntegrationTicket } from '../integrations';
import { TicketingIntegrationTicketField } from '../integrations/ticketingIntegrations.types';

/** Zod Schemas and Types for WorkflowInputValue payloads */
/** Workflows payloads are the data stored inside a WorkflowInputValue at workflow runtime */

const zBaseIntegrationInputPayload = z.object({
  integrationData: z.record(z.string()) // Should be overridden with specific integration data types
});

export const zTextWorkflowInputPayload = z.object({
  text: z.string()
});

export const zContentWorkflowInputPayload = z.object({
  contentId: z.string().cuid().or(z.string().uuid())
});

export const zTicketIntegrationInputPayload =
  zBaseIntegrationInputPayload.extend({
    integrationData: zTicketingIntegrationTicket
  });

export const zTextOrContentWorkflowInputPayload = z.union([
  zTextWorkflowInputPayload,
  zContentWorkflowInputPayload
]);

export const zWorkflowInputPayload = z.union([
  zTextOrContentWorkflowInputPayload,
  zTicketIntegrationInputPayload
]);

export type TextWorkflowInputPayload = z.infer<
  typeof zTextWorkflowInputPayload
>;
export type ContentWorkflowInputPayload = z.infer<
  typeof zContentWorkflowInputPayload
>;
export type TicketIntegrationInputPayload = z.infer<
  typeof zTicketIntegrationInputPayload
>;

export type TextOrContentWorkflowInputPayload = z.infer<
  typeof zTextOrContentWorkflowInputPayload
>;

/** Mapping of WorkflowInputType to its corresponding value payload */

export const Z_WORKFLOW_INPUT_TYPE_PAYLOADS = {
  [WorkflowInputType.TEXT_OR_CONTENT]: zTextOrContentWorkflowInputPayload,
  [WorkflowInputType.TICKETING_INTEGRATION_TICKET]:
    zTicketIntegrationInputPayload
} as const satisfies Record<WorkflowInputType, z.ZodType>;

export type WorkflowInputPayload<
  T extends WorkflowInputType = WorkflowInputType
> = z.infer<(typeof Z_WORKFLOW_INPUT_TYPE_PAYLOADS)[T]>;

// Const that maps WorkflowInputType to the fields of the payload
const Z_WORKFLOW_INTEGRATION_INPUT_PAYLOAD_FIELDS = {
  [WorkflowInputType.TICKETING_INTEGRATION_TICKET]: Object.values(
    TicketingIntegrationTicketField
  )
} as const satisfies Record<
  IntegrationWorkflowInputType,
  readonly TicketingIntegrationTicketField[]
>;

/** Type Guards */
export function isTextWorkflowInputPayload(
  payload: WorkflowInputPayload<WorkflowInputType>
): payload is TextWorkflowInputPayload {
  return 'text' in payload;
}

export function isContentWorkflowInputPayload(
  payload: WorkflowInputPayload<WorkflowInputType>
): payload is ContentWorkflowInputPayload {
  return 'contentId' in payload;
}

export function isTextOrContentWorkflowInputPayload(
  payload: WorkflowInputPayload<WorkflowInputType>
): payload is TextOrContentWorkflowInputPayload {
  return 'text' in payload || 'contentId' in payload;
}

export function isIntegrationInputPayload(
  payload: WorkflowInputPayload<WorkflowInputType>
): payload is WorkflowInputPayload<IntegrationWorkflowInputType> {
  return 'integrationData' in payload;
}

export { Z_WORKFLOW_INTEGRATION_INPUT_PAYLOAD_FIELDS };
