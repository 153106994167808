import { Llm, SupportedLlm } from './llm.consts';

/**
 * DELETED - Message was deleted
 * ERROR - An error occurred while generating this message
 * OK - The message was created (and generated if not a user message) successfully
 * PENDING - The message is in the process of being generated
 * QUEUED - The message is queued to be processed in the future
 *   - E.g. A workflow step that has not began execution yet
 * READY - The message was queued, and is now awaiting user action to begin processing
 */
export enum ChatMessageState {
  DELETED = 'deleted',
  ERROR = 'error',
  OK = 'ok',
  PENDING = 'pending',
  QUEUED = 'queued',
  READY = 'ready',
  STOPPED = 'stopped'
}
export const TERMINAL_CHAT_MESSAGE_STATES = [
  ChatMessageState.DELETED,
  ChatMessageState.ERROR,
  ChatMessageState.OK,
  ChatMessageState.STOPPED
] as const satisfies ChatMessageState[];

export type TerminalChatMessageState =
  (typeof TERMINAL_CHAT_MESSAGE_STATES)[number];

export const isTerminalChatMessageState = (
  state: string
): state is TerminalChatMessageState =>
  TERMINAL_CHAT_MESSAGE_STATES.includes(state as any);

export enum ChatMessageRole {
  ASSISTANT = 'assistant',
  SYSTEM = 'system',
  USER = 'user'
}

export enum ChatMessageContentType {
  KNOWLEDGE_STORE = 'KNOWLEDGE_STORE',
  LIBRARY_SEARCH = 'LIBRARY_SEARCH',
  OUTPUT = 'OUTPUT',
  SPECIFIED = 'SPECIFIED'
}

export enum ContentMode {
  ALL = 'All',
  CSV_ROW_BY_ROW = 'Row-By-Row',
  PARALLEL = 'Parallel'
}

export const GEMINI_CHAT_LLMS = [
  Llm.GEMINI_1_5_PRO,
  Llm.GEMINI_1_5_FLASH
] as const satisfies readonly SupportedLlm[];

export const isGeminiChatLlm = (model: Llm): boolean =>
  GEMINI_CHAT_LLMS.includes(model as any);

// These models are displayed as an option for chat
// and workflows to all users.
// Note: The order of the list is also the order they're presented to the user
export const DISPLAYED_CHAT_LLMS = [
  Llm.GEMINI_1_5_PRO,
  Llm.GEMINI_1_5_FLASH,
  Llm.LLAMA_3_1_70B,
  Llm.LLAMA_3_70B,
  Llm.CLAUDE_3_5_SONNET,
  Llm.CLAUDE_3_OPUS,
  Llm.CLAUDE_3_SONNET,
  Llm.CLAUDE_3_HAIKU,
  Llm.COMMAND_R,
  Llm.GPT_3_5_TURBO,
  Llm.GPT_4O,
  Llm.GPT_4O_MINI,
  Llm.GPT_4_TURBO,
  Llm.GRANITE_13B_CHAT_V2,
  Llm.MIXTRAL_GROQ,
  Llm.QWEN2_72B_INSTRUCT,
  Llm.WHITERABBITNEO_33B
] as const satisfies readonly SupportedLlm[];

export const FEATURE_FLAG_DISPLAYED_CHAT_LLMS = [
  Llm.WHITERABBITNEO_2_5_QWEN_2_5_32B,
  Llm.SAUL_INSTRUCT_V1
] as const satisfies readonly SupportedLlm[];

export enum ChatAccessLevel {
  EDIT = 'EDIT',
  RESTRICTED = 'RESTRICTED',
  VIEW = 'VIEW'
}

// This is the message that is substituted for error messages in chat history
// before sending to an LLM in order to support history retention and LLMs that require
// alternating message roles.
export const CHAT_HISTORY_ERROR_MESSAGE = 'There was an error.';

export const LLAMA_INDEXER_GENERATE_CHAT_PATH = '/generate-chat';
