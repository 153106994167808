import {
  SupportedLlm,
  PromptTemplateTagType,
  getPromptTemplateTag,
  WorkflowStepType,
  WorkflowIntegrationActionStepType
} from '@kindo/universal';

import { useAppDispatch } from './typedReduxHooks';
import useIsSharedWorkflow from './useIsSharedWorkflow';
import useToast, { ToastType } from './useToast';

import {
  NEW_STATIC_CONTENT_MUSTACHE_TEMPLATE_VALUE,
  NEW_USER_INPUT_MUSTACHE_TEMPLATE_VALUE,
  workflowBuilderActions
} from '~/redux/reducers/workflowBuilderSlice';
import { nextTrpc } from '~/trpc';
import {
  Workflow,
  BuilderWorkflowIntegrationActionStep,
  BuilderWorkflowLlmStep,
  BuilderWorkflowStatus,
  BuilderWorkflowStep,
  isIntegrationActionWorkflowBuilderStep,
  isLlmWorkflowBuilderStep,
  isPromptTemplateWorkflowBuilderStepInput,
  isIntegrationWorkflowBuilderStepInput,
  BuilderWorkflowStepStaticContent
} from '~/types';

interface UseActiveBuilderStepArgs {
  activeStep: BuilderWorkflowStep | null; // TODO: Update typing to work for other WorkflowSteps
  refetchWorkflow: () => void;
  workflow: Workflow;
}

interface UseActiveBuilderStepReturnObject {
  canSave: boolean;
  clearActiveStep: () => void;
  isNewStep: boolean;
  isSavingActiveStep: boolean;
  requiresStaticContentConfirmation: boolean;
  saveActiveStep: () => void;
  updateActiveStepAction: (value: WorkflowIntegrationActionStepType) => void;
  updateActiveStepModel: (value: SupportedLlm) => void;
  updateActiveStepPrompt: (value: string) => void;
}

/**
 * Custom hook for performing actions or returning data on
 * the active step in the workflow builder
 */
const useActiveBuilderStep = ({
  activeStep,
  workflow,
  refetchWorkflow
}: UseActiveBuilderStepArgs): UseActiveBuilderStepReturnObject => {
  // Custom Hooks
  const { enqueueToast } = useToast();
  const { isSharedWorkflow } = useIsSharedWorkflow(workflow);

  // Redux
  const dispatch = useAppDispatch();

  // TRPC Mutations
  const handleSaveSuccess = () => {
    refetchWorkflow();
    dispatch(workflowBuilderActions.markActiveStepSaved());
    enqueueToast({
      message: 'Step saved successfully.',
      type: ToastType.SUCCESS
    });
  };

  const addLlmStepMutation = nextTrpc.workflows.addLlmStep.useMutation({
    onSuccess: handleSaveSuccess,
    onError: (error) => {
      console.error('Failed to save LLM step: ', error);
      enqueueToast({
        message: 'Failed to save LLM step.',
        type: ToastType.ERROR
      });
    }
  });
  const editLlmStepMutation = nextTrpc.workflows.editLlmStep.useMutation({
    onSuccess: handleSaveSuccess,
    onError: (error) => {
      console.error('Failed to edit LLM step: ', error);
      enqueueToast({
        message: 'Failed to save LLM step update.',
        type: ToastType.ERROR
      });
    }
  });

  const addIntegrationActionStepMutation =
    nextTrpc.workflows.addIntegrationActionStep.useMutation({
      onSuccess: handleSaveSuccess,
      onError: (error) => {
        console.error('Failed to save action step: ', error);
        enqueueToast({
          message: 'Failed to save action step.',
          type: ToastType.ERROR
        });
      }
    });
  const editIntegrationActionStepMutation =
    nextTrpc.workflows.editIntegrationActionStep.useMutation({
      onSuccess: handleSaveSuccess,
      onError: (error) => {
        console.error('Failed to edit action step: ', error);
        enqueueToast({
          message: 'Failed to save action step update.',
          type: ToastType.ERROR
        });
      }
    });

  // Utility functions
  const filterContentIds = (
    activeStepStaticContent: BuilderWorkflowStepStaticContent[],
    activeStepPrompt: string
  ) =>
    activeStepStaticContent
      .filter((content) =>
        activeStepPrompt.includes(
          getPromptTemplateTag(content.id, PromptTemplateTagType.STATIC_CONTENT)
        )
      )
      .map((content) => content.id);

  // Exported functions
  const updateActiveStepPrompt = (value: string) => {
    dispatch(workflowBuilderActions.updateActiveStepPrompt(value));
  };

  const updateActiveStepModel = (value: SupportedLlm) =>
    dispatch(workflowBuilderActions.updateActiveStepModel(value));

  const updateActiveStepAction = (value: WorkflowIntegrationActionStepType) => {
    dispatch(
      workflowBuilderActions.updateActiveStepAction({
        integrationAction: value
      })
    );
  };

  const clearActiveStep = () => {
    dispatch(workflowBuilderActions.clearActiveStep());
  };

  const isNewStep = activeStep?.status === BuilderWorkflowStatus.NEW;

  const saveLlmWorkflowStep = (step: BuilderWorkflowLlmStep) => {
    if (!isLlmWorkflowBuilderStep(step)) {
      console.error('Active step is not an LLM step');
      enqueueToast({
        message: 'Active step is not an LLM step.',
        type: ToastType.ERROR
      });
      return;
    }
    const { promptTemplate, model, staticContent, status, stepNumber, inputs } =
      step;
    // Only send inputs that exist in the prompt template
    const filteredUserInputs = inputs
      .filter(isPromptTemplateWorkflowBuilderStepInput)
      .filter((input) =>
        promptTemplate.includes(
          getPromptTemplateTag(
            input.templateResolutionName,
            PromptTemplateTagType.USER_INPUT
          )
        )
      )
      // eslint-disable-next-line max-len
      // TODO: Update API requests to accept templateResolutionName instead of templateResolutionName
      .map((input) => ({
        templateResolutionName: input.templateResolutionName,
        displayName: input.displayName
      }));
    const filteredStaticContentIds = filterContentIds(
      staticContent,
      promptTemplate
    );

    if (isNewStep) {
      addLlmStepMutation.mutate({
        prompt: promptTemplate,
        model,
        stepNumber,
        inputs: filteredUserInputs,
        staticContentIds: filteredStaticContentIds,
        workflowId: workflow.id
      });
    } else if (status === BuilderWorkflowStatus.MODIFIED) {
      // Display name is intentionally omitted
      // It should be set separately and intentionally in WorkflowStepPreview
      editLlmStepMutation.mutate({
        prompt: promptTemplate,
        model,
        stepNumber,
        inputs: filteredUserInputs,
        staticContentIds: filteredStaticContentIds,
        id: step.id
      });
    }
  };

  const saveIntegrationActionWorkflowStep = (
    step: BuilderWorkflowIntegrationActionStep
  ) => {
    const { action, integration, status, stepNumber, inputs } = step;
    if (!action || !integration || inputs.length === 0) {
      console.error('Action, integration, or inputs not set');
      enqueueToast({
        message:
          'Please make sure to select an action, integration, and input.',
        type: ToastType.ERROR
      });
      return;
    }

    const inputIds = inputs
      .filter(isIntegrationWorkflowBuilderStepInput)
      .map((input) => input.id);
    if (isNewStep) {
      addIntegrationActionStepMutation.mutate({
        type: action,
        integrationName: integration,
        stepNumber,
        inputIds,
        workflowId: workflow.id
      });
    } else if (status === BuilderWorkflowStatus.MODIFIED) {
      editIntegrationActionStepMutation.mutate({
        type: action,
        integrationName: integration,
        stepNumber,
        inputIds,
        id: step.id
      });
    }
  };
  const saveActiveStep = () => {
    if (!activeStep) {
      console.error('No active step to save');
      enqueueToast({
        message: 'No active step to save.',
        type: ToastType.ERROR
      });
      return;
    }

    if (isLlmWorkflowBuilderStep(activeStep)) {
      saveLlmWorkflowStep(activeStep);
    } else if (isIntegrationActionWorkflowBuilderStep(activeStep)) {
      saveIntegrationActionWorkflowStep(activeStep);
    } else {
      console.error(`Failed to save step, unknown step type: ${activeStep}`);
      enqueueToast({
        message: 'Failed to save step, unknown step type',
        type: ToastType.ERROR
      });
    }
  };

  const hasEmptyInputNode =
    isLlmWorkflowBuilderStep(activeStep) &&
    (activeStep?.promptTemplate?.includes(
      `${NEW_USER_INPUT_MUSTACHE_TEMPLATE_VALUE}`
    ) ||
      activeStep?.promptTemplate?.includes(
        `${NEW_STATIC_CONTENT_MUSTACHE_TEMPLATE_VALUE}`
      ));

  const isSavingActiveStep =
    addLlmStepMutation.isLoading ||
    editLlmStepMutation.isLoading ||
    addIntegrationActionStepMutation.isLoading ||
    editIntegrationActionStepMutation.isLoading;

  const getCanSave = (step: BuilderWorkflowStep | null): boolean => {
    if (!step || step.status === BuilderWorkflowStatus.SAVED) return false;

    if (isLlmWorkflowBuilderStep(step)) {
      return !!step.promptTemplate && !hasEmptyInputNode;
    }
    // Integration action steps cannot be saved without
    // the action, integration, and input set
    if (isIntegrationActionWorkflowBuilderStep(step)) {
      return !!step.action && !!step.integration && step.inputs.length > 0;
    }
    return false;
  };
  const canSave: boolean = getCanSave(activeStep);

  const savedStep =
    activeStep?.stepNumber !== undefined
      ? workflow?.steps[activeStep.stepNumber - 1]
      : null;

  const filteredStaticContentIds = isLlmWorkflowBuilderStep(activeStep)
    ? filterContentIds(
        activeStep?.staticContent || [],
        activeStep?.promptTemplate || ''
      )
    : [];

  const newStaticContentAdded =
    savedStep?.type === WorkflowStepType.LLM
      ? filteredStaticContentIds.filter(
          (activeStaticContentId) =>
            !savedStep?.staticContent.some(
              (savedStaticContent) =>
                savedStaticContent.id === activeStaticContentId
            )
        ).length > 0
      : false;

  const requiresStaticContentConfirmation =
    isSharedWorkflow && newStaticContentAdded;

  return {
    canSave,
    requiresStaticContentConfirmation,
    isSavingActiveStep,
    isNewStep,
    clearActiveStep,
    saveActiveStep,
    updateActiveStepPrompt,
    updateActiveStepModel,
    updateActiveStepAction
  };
};

export default useActiveBuilderStep;
