import { useAppDispatch } from './typedReduxHooks';
import useToast, { ToastType } from './useToast';

import {
  BuilderWorkflowConfig,
  workflowBuilderActions
} from '~/redux/reducers/workflowBuilderSlice';
import { nextTrpc } from '~/trpc';
import { BuilderWorkflowStatus } from '~/types';

interface UseWorkflowConfigArgs {
  refetchWorkflow: () => void;
  workflowConfig: BuilderWorkflowConfig | null;
  workflowId: string;
}

interface UseWorkflowConfigReturnObject {
  canSaveWorkflowConfig: boolean;
  isSavingWorkflowConfig: boolean;
  markWorkflowConfigSaved: () => void;
  saveWorkflowConfig: () => Promise<void>;
  updateWorkflowConfigDescription: (value: string) => void;
  updateWorkflowConfigInstructions: (value: string) => void;
  updateWorkflowConfigName: (value: string) => void;
}

const useWorkflowConfig = ({
  workflowConfig,
  refetchWorkflow,
  workflowId
}: UseWorkflowConfigArgs): UseWorkflowConfigReturnObject => {
  // Custom Hooks
  const { enqueueToast } = useToast();

  // Redux
  const dispatch = useAppDispatch();

  // TRPC Mutations
  const handleSaveSuccess = () => {
    refetchWorkflow();
    dispatch(workflowBuilderActions.markWorkflowConfigSaved());
    enqueueToast({
      message: 'Agent configuration saved successfully.',
      type: ToastType.SUCCESS
    });
  };

  const editWorkflowConfigMutation = nextTrpc.workflows.update.useMutation({
    onSuccess: handleSaveSuccess,
    onError: (error) => {
      console.error(error);
      enqueueToast({
        message: 'Failed to update workflow configuration.',
        type: ToastType.ERROR
      });
    }
  });

  // Exported functions
  const updateWorkflowConfigDescription = (value: string) => {
    dispatch(workflowBuilderActions.updateWorkflowConfigDescription(value));
  };

  const updateWorkflowConfigInstructions = (value: string) => {
    dispatch(workflowBuilderActions.updateWorkflowConfigInstructions(value));
  };

  const updateWorkflowConfigName = (value: string) => {
    dispatch(workflowBuilderActions.updateWorkflowConfigName(value));
  };

  const saveWorkflowConfig = async (): Promise<void> => {
    if (!workflowConfig) {
      console.error('No workflow config to save.');
      enqueueToast({
        message: 'No workflow config to save.',
        type: ToastType.ERROR
      });
      return;
    }

    const { description, instructions, name, status } = workflowConfig;

    if (status === BuilderWorkflowStatus.MODIFIED) {
      await editWorkflowConfigMutation.mutateAsync({
        workflowId,
        description: description.trim(),
        displayName: name.trim(),
        systemPrompt: instructions.trim() || null
      });
    }
  };

  const markWorkflowConfigSaved = () => {
    dispatch(workflowBuilderActions.markWorkflowConfigSaved());
  };

  const isSavingWorkflowConfig = editWorkflowConfigMutation.isLoading;

  const canSaveWorkflowConfig =
    workflowConfig?.status === BuilderWorkflowStatus.MODIFIED;

  return {
    canSaveWorkflowConfig,
    isSavingWorkflowConfig,
    markWorkflowConfigSaved,
    saveWorkflowConfig,
    updateWorkflowConfigDescription,
    updateWorkflowConfigInstructions,
    updateWorkflowConfigName
  };
};

export default useWorkflowConfig;
